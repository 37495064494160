















import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { DeviceRelation, DataApplication } from '@/models/data/models';
import { Model } from '@/models/device/models';
import ConfigSelector from './ConfigSelector.vue';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseListEventType } from '@/models/core/base';

@Component({
  components: {
    ConfigSelector,
  },
})
export default class DeviceRelationList extends Vue {
  DeviceRelation = DeviceRelation;
  destroySubject = new Subject<void>();

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  get filter() {
    return {
      application: this.$store.getters['global/application']
        ? this.$store.getters['global/application'].id
        : 'unknown', // explicitly set to 'unknown' to not show anything
      order_by: 'device_name_asc',
    };
  }
}
